import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { Product } from 'src/app/shared/models/product.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpProductService {
  private baseUrl = environment.baseUrl;
  private headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).authToken}`, // Include any other headers you need
    });
  }

  // getProductsApi() {
  //   return this.httpClient.get<Product[]>(`${this.baseUrl}Product`, {
  //     observe: 'response',
  //   });
  // }
  public  getProductsApi() {
    return  this.httpClient.get(this.baseUrl +"Product", { headers: this.headers });
  }

  addProductApi(body) {
    return this.httpClient.post(`${this.baseUrl}Product`, body, {
      observe: 'response',
      headers: this.headers
    });
  }

  editProductApi(body, hsCode) {
    return this.httpClient.put(`${this.baseUrl}Product/${hsCode}`, body, {
      observe: 'response',
      headers: this.headers
    });
  }

  getSingleProductApi(hsCode) {
    return this.httpClient.get<Product>(`${this.baseUrl}Product/${hsCode}`, {
      observe: 'response',
      headers: this.headers
    });
  }
  deleteProduct(hsCode) {
    return this.httpClient.delete(`${this.baseUrl}Product/${hsCode}`, {
      observe: 'response',
      headers: this.headers
    });
  }
}
